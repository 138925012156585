import React,{useEffect, useState, useRef} from 'react'
import './App.css';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Login from './components/login/LoginComponent';
import Index from './components/index/IndexComponent';
import Registration from './components/registration/RegistrationComponent';
import Activate from './components/activate/ActivateComponent';
import Forgot from './components/forgot/ForgotComponent';
import Pay from './components/pay/PayComponent';
import RemoveUser from './components/removeUser/RemoveUserComponent';
import PayLoad from './components/payLoad/PayLoadComponent';
import Create from './components/create/CreateComponent';
import UserComponent from './components/user/UserComponent';
import ContactComponent from './components/contact/ContactComponent';
import LanguageComponent from './components/language/LanguageComponent';
import UsersComponent from './components/users/UsersComponent';
import TemplatesComponent from './components/templates/TemplatesComponent';
import Sign from './components/sign/SignComponent';
import {Grid  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {apiLogin} from './services/api/accounts/Login';
import { useTranslation } from "react-i18next";
import { ModelTraining } from '@mui/icons-material';
import ImageRepoComponent from './components/imageRepo/ImageRepoComponent';

const isAuthenticated = localStorage.getItem('token') != null && localStorage.getItem('token') != '';
const role = localStorage.getItem('role');
const email = localStorage.getItem('email');
const fullName = localStorage.getItem('fullName');
const initial = localStorage.getItem('firstName')!==null ? (localStorage.getItem('firstName').substring(0,1)+''+localStorage.getItem('lastName').substring(0,1)):'';
const avatar = localStorage.getItem('avatar') != null ? localStorage.getItem('avatar') : null;

const App = (props, match) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [isMenu, setIsMenu] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [isRemoveUser, setIsRemoveUser] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [isOpenContacts, setIsOpenContacts] = useState(false);
    const [isOpenDocuments, setIsOpenDocuments] = useState(false);
    const [isOpenTemplates, setIsOpenTemplates] = useState(false);
    const [isOpenImageRepo, setIsOpenImageRepo] = useState(false);
    const [isOpenLanguage, setIsOpenLanguage] = useState(false);
    const [isOpenUsers, setIsOpenUsers] = useState(false);
    const [isOpenPayLoad, setIsOpenPayLoad] = useState(false);
    const [isOpenPay, setIsOpenPay] = useState(false);
    const [currentUser, setCurrentUser] = useState(false);
    const [isHaveToPaid, setIsHaveToPaid] = useState();
    const [disabledPayment, setDisabledPayment] = useState(false);
    const [language, setLanguage] = useState('PL');
    const [languageText, setLanguageText] = useState('Polski');
    const { t, i18n, ready } = useTranslation();
    const anchorRef = useRef(null);

    useEffect(() => {
      async function fetchData() {
          await refreshItems();
          //window["loadWindow"]();

          if(localStorage.getItem('Language')==='PL')
          {
              setLanguage('PL');
              setLanguageText('Polski');
          }
          else if(localStorage.getItem('Language')==='EN')
          {
              setLanguage('EN');
              setLanguageText('English');
          }
          else if(localStorage.getItem('Language')==='FR')
          {
              setLanguage('FR');
              setLanguageText('Français');
          }

          let user = await apiLogin.getCurrentUser();
          if(user!==null)
          {
              setCurrentUser(user);
          }
      }
      fetchData();

    }, []);

    const refreshItems = async ()=>{
      let data = await apiLogin.getNotificationUser();
      setItems(data.items);
    }

    const logOff = () =>{
      localStorage.setItem('token', '');
      localStorage.setItem('fullName', '');
      localStorage.setItem('role', '');
      localStorage.setItem('email', '');
      localStorage.setItem('avatar', '');
      window.location.href = "/";
    }

    const closeAll = () => {
      setIsMenu(false);
      setIsOpenLanguage(false);
      setIsOpenPayLoad(false);
      setIsOpenContacts(false);
      setIsOpenTemplates(false);
      setIsOpenImageRepo(false);
      setIsOpenUsers(false);
      setIsOpenUser(false);
    }

    const showUser = () =>{
      setIsOpenUser(true);
      closeAll();
      setIsOpenUser(true);
    }

    const readNotification = async (id, documentId) =>{
      let data = await apiLogin.readNotifcation(id);
      window.location.href = "/sign?id="+documentId;
    }

    const showLanguage = () =>{
      setIsMenu(false);
      closeAll();
      setIsOpenLanguage(true);
    }

    const showContacts = () =>{
      setIsMenu(false);
      closeAll();
      setIsOpenContacts(true);
    }

    const showDocuments = () =>{
      setIsMenu(false);
      closeAll();
      setIsOpenTemplates(true);
    }

    const showImageRepo = () =>{
      setIsMenu(false);
      closeAll();
      setIsOpenImageRepo(true);
    }

    const showUsers = () =>{
      setIsMenu(false);
      closeAll();
      setIsOpenUsers(true);
    }

    return (
      <div>
        <div className={(email===null || email==='') ? 'top-2':'top'}>
            {(email===null || email==='') && 
              <div className={`main-wrapper`}>
                <Grid container>
                    <Grid item style={{maxWidth: "130px"}}>
                      <a href="/login" className='logo'></a>
                    </Grid>
                    <Grid item xs>
                    </Grid>
                    <Grid item style={{maxWidth: "100px"}}>
                        <a className="language not-mobile" onClick={()=>showLanguage()}><i className='language-svg not-mobile'></i>&nbsp;&nbsp;{language}</a>
                    </Grid>
                </Grid>
              </div>
            }
            {email!==null && email!=='' && 
              <Grid container>
                  <Grid item style={{maxWidth: "130px"}}>
                    <a href="/login" className='logo'></a>
                  </Grid>
                  <Grid item xs>
                    <a className="language not-mobile" onClick={()=>showLanguage()}><i className='language-svg not-mobile'></i>&nbsp;&nbsp;{language}</a>
                  </Grid>
                  <Grid item style={{marginTop: "5px"}} className="right-element">
                    <a className='full-name'><i className='notification-icon' onClick={()=>{setIsNotification(!isNotification);setIsMenu(false);}} onMouseEnter={()=>{setIsNotification(true);setIsMenu(false);}}></i>
                    <span onClick={()=>setIsMenu(!isMenu)} className='initial2'>{initial}</span>
                    </a>
                    {isNotification && <ul className='menu' onMouseLeave={()=>setIsNotification(false)}>
                      {items.map((row, index) => {
                        return(<li key={index} className='notification'>
                            <Grid container>
                              <Grid item xs={12}>
                                <b>{row.createdDateName}</b>
                              </Grid>
                              <Grid item xs={12}>
                                {row.description.indexOf('został podpisany')>0 &&
                                  <h5>Nowy podpis</h5>
                                }
                                {row.description.indexOf('został podpisany')<=0 &&
                                  <h5>Podpisz dokument</h5>
                                }
                              </Grid>
                              <Grid item xs={12}>
                                <p onClick={()=>readNotification(row.id,row.documentId)}>{row.description}</p>
                              </Grid>
                            </Grid>
                          </li>)
                      })}
                    </ul>
                    }
                    {isMenu && <ul className='menu only-mobile' onMouseLeave={()=>setIsMenu(false)}>
                      <li onClick={()=>showUser()}>{t("app_text1")}</li>
                      <li onClick={()=>showContacts()}>{t("app_text2")}</li>
                      <li onClick={()=>showDocuments()}>{t("app_text2c")}</li>
                      <li onClick={()=>showImageRepo()}>{t("app_text2d")}</li>
                      {localStorage.getItem('role')==='2' && <li onClick={()=>showUsers()}>{t("app_text3")}</li>}
                      {<li onClick={()=>{closeAll();setIsOpenPayLoad(true);}}>{t("app_text2b")}</li>}
                      <li><a href="https://signsafe.app/regulamin-serwisu-internetowego/">{t("app_text4")}</a></li>
                      <li><a href="https://signsafe.app/polityka-prywatnosci/">{t("app_text5")}</a></li>
                      <li><a onClick={()=>{setIsRemoveUser(true)}}>{t("app_text6b")}</a></li>
                      <li><a onClick={()=>{logOff()}}>{t("app_text6")}</a></li>
                    </ul>
                    }
                  </Grid>
              </Grid>
            }
            {email!==null && email!=='' && 
              <div className={`main-wrapper`}>
                <Grid container>
                    <UserComponent isOpenUser={isOpenUser} setIsOpenUser={setIsOpenUser} setIsOpenPayLoad={setIsOpenPayLoad} setIsBlur={setIsBlur}></UserComponent>
                </Grid>
                <Grid container>
                    <ContactComponent isOpenContacts={isOpenContacts} setIsOpenContacts={setIsOpenContacts}></ContactComponent>
                </Grid>
                <Grid container>
                    <TemplatesComponent isOpenTemplates={isOpenTemplates} setIsOpenTemplates={setIsOpenTemplates} isOpenDocuments={isOpenDocuments} setIsOpenDocuments={setIsOpenDocuments}></TemplatesComponent>
                </Grid>
                <Grid container>
                    <ImageRepoComponent isOpenImageRepo={isOpenImageRepo} setIsOpenImageRepo={setIsOpenImageRepo}></ImageRepoComponent>
                </Grid>
                <Grid container>
                    <UsersComponent isOpenUsers={isOpenUsers} setIsOpenUsers={setIsOpenUsers}></UsersComponent>
                </Grid>
                <Grid container>
                    <Pay isBlur={isBlur} setIsBlur={setIsBlur} isOpenPayLoad={isOpenPayLoad} setDisabledPayment={setDisabledPayment} setIsOpenPayLoad={setIsOpenPayLoad} isOpenPay={isOpenPay} isHaveToPaid={isHaveToPaid}  setIsOpenPay={setIsOpenPay}></Pay>
                </Grid>
                <Grid container>
                    <PayLoad isBlur={isBlur} setIsBlur={setIsBlur} isOpenPayLoad={isOpenPayLoad} setIsOpenPayLoad={setIsOpenPayLoad}></PayLoad>
                </Grid>
                <Grid container>
                    <RemoveUser isRemoveUser={isRemoveUser} setIsRemoveUser={setIsRemoveUser} logOff={logOff}></RemoveUser>
                </Grid>
              </div>
            }
        </div>
        {email!==null && email!=='' && 
          <Grid container>
            <Grid item className='left-menu'>
                <Grid container className='mt-10 ml-10'>
                    <Grid item xs={2}>
                      <span className='initial'>{initial}</span>
                    </Grid>
                    <Grid item xs>
                        <Grid container>
                             <Grid item>
                                <p className='fullName'>{fullName}</p>
                             </Grid>
                        </Grid>
                        <Grid container>
                             <Grid item>
                                {currentUser.deadline && 
                                  <a className='account-type'><i className='account-icon'></i>&nbsp;{t("app_text20")}</a>
                                }
                                {!currentUser.deadline && 
                                  <a className='account-type'><i className='account-icon'></i>&nbsp;{t("app_text21")}</a>
                                }
                             </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <Grid container>
                  <Grid item xs={12}>
                    <p className="menu-info">{t("app_text21b")}</p>
                  </Grid>
                  {(role==='2' || role==='3') &&
                    <Grid item xs={12}>
                      <a className="menu-item menu-button" href="/create"><i class="add-icon"></i>&nbsp;{t("app_text22")}</a>
                    </Grid>
                  }
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" href="/index"><i class="document-icon"></i>&nbsp;{t("app_text23")}</a>
                  </Grid>
                </Grid>
                <br/>
                <Grid container>
                  <Grid item xs={12}>
                    <p className="menu-info">{t("app_text24")}</p>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>showContacts()}><i class="contact-icon"></i>&nbsp;{t("app_text25")}</a>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>showDocuments()}><i class="template-icon"></i>&nbsp;{t("app_text26")}</a>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>showImageRepo()}><i class="images-icon"></i>&nbsp;{t("app_text27")}</a>
                  </Grid>
                </Grid>
                <br/>
                <Grid container>
                <Grid item xs={12}>
                    <p className="menu-info">Twoje konto</p>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>showUser()}><i class="user-icon"></i>&nbsp;{t("app_text28")}</a>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>{closeAll();setIsOpenPayLoad(true);setIsBlur(true)}}><i class="payments-icon"></i>&nbsp;{t("app_text29")}</a>
                  </Grid>
                  <Grid item xs={12} className='mt-6'>
                    <a className="menu-item" onClick={()=>showUsers()}><i class="company-icon"></i>&nbsp;{t("app_text30")}</a>
                  </Grid>
                </Grid>
                <Grid container>

                </Grid>
                <Grid container className='bottom-options'>
                  <Grid item xs={12}>
                    <a className="menu-item logout-button" onClick={()=>logOff()}><a className='logout-sub'><i class="logout-icon"></i>&nbsp;{t("app_text31")}</a></a>
                  </Grid>
                  <Grid item xs={12}>
                    <a className='bottom-href' href="https://signsafe.app/regulamin-serwisu-internetowego/">{t("app_text32")}</a>
                    <a className='bottom-href text-right' href="https://signsafe.app/polityka-prywatnosci/">{t("app_text33")}</a>
                  </Grid>
                  <Grid item xs={12}>
                    <a className='bottom-href cursor' onClick={()=>{setIsRemoveUser(true)}}>{t("app_text34")}</a>
                    <a className='bottom-href text-right' onClick={()=>{setIsOpenLanguage(true)}}>{t("app_text35")}: {languageText}</a>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        }
        <div id="all" className={`
            ${email ? 'all' : ''}
          `.trim()} >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="sign" element={<Sign />} />
              <Route path="create" element={<Create isBlur={isBlur} setIsBlur={setIsBlur} setDisabledPayment={setDisabledPayment} disabledPayment={disabledPayment} setIsHaveToPaid={setIsHaveToPaid} setIsOpenPay={setIsOpenPay} isOpenPayLoad={isOpenPayLoad} setIsOpenTemplates={setIsOpenTemplates}/>} />
              <Route path="login" element={<Login />} />
              <Route path="index" element={<Index setIsOpenPay={setIsOpenPay} isOpenPayLoad={isOpenPayLoad}/>} />
              <Route path="registration" element={<Registration />} />
              <Route path="activate" element={<Activate />} />
              <Route path="forgot" element={<Forgot />} />
            </Routes>
          </BrowserRouter>
        </div>
        <Grid container>
            <LanguageComponent setLanguage={setLanguage} setLanguageText={setLanguageText} language={language} isOpenLanguage={isOpenLanguage} setIsOpenLanguage={setIsOpenLanguage}></LanguageComponent>
        </Grid>
      </div>
    );
}

export default App;