import React, {  useEffect, useState } from 'react';
import { Button,Grid,  RadioGroup, Radio,FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from "react-i18next";

const LanguageComponent = (props) => {
    const { t, i18n, ready } = useTranslation();
    const [classButton1, setClassButton1] = useState('button-sign-active');
    const [classButton2, setClassButton2] = useState('button-sign');
    const [classButton3, setClassButton3] = useState('button-sign');
    const [language, setLanguage] = useState(1);

    useEffect(() => {
        if(props.language==='EN')
        {
            setClassButton2('button-sign-active');
            setClassButton1('button-sign');
            setClassButton3('button-sign');
        }
        else if(props.language==='FR')
        {
            setClassButton2('button-sign');
            setClassButton1('button-sign');
            setClassButton3('button-sign-active');
        }
    }, [props.language]);
    
    const handleClose = () => {
        props.setIsOpenLanguage(false);
    };

    const closeDialog = () => {
        if(language===1)
        {
            localStorage.setItem('Language', 'PL');
            i18n.changeLanguage("pl");
            props.setLanguage('PL');
            props.setLanguageText('Polski');
        }
        if(language===2)
        {
            localStorage.setItem('Language', 'EN');
            i18n.changeLanguage("en");
            props.setLanguage('EN');
            props.setLanguageText('English');
        }
        if(language===3)
        {
            localStorage.setItem('Language', 'FR');
            i18n.changeLanguage("fr");
            props.setLanguage('FR');
            props.setLanguageText('Français');
        }
        props.setIsOpenLanguage(false);
    };

    const cancel = () => {
        props.setIsOpenLanguage(false);
    }

    return (
        <div>
            <Dialog open={props.isOpenLanguage} onClose={handleClose} style={{padding:"10px"}}>
                <div className='dialog-user dialog-template'>
                    <DialogTitle>
                        {t("language_text1")}
                    </DialogTitle>
                    <DialogContent>
                        <br/>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item xs={4}>
                                <Button className={classButton1} onClick={()=>{setLanguage(1);setClassButton1('button-sign-active');setClassButton2('button-sign');setClassButton3('button-sign')}}>{t("language_text2")}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button className={classButton3} onClick={()=>{setLanguage(3);setClassButton1('button-sign');setClassButton2('button-sign');setClassButton3('button-sign-active')}}>{t("language_text3b")}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button className={classButton2} onClick={()=>{setLanguage(2);setClassButton1('button-sign');setClassButton2('button-sign-active');setClassButton3('button-sign')}}>{t("language_text3")}</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions >
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={1}>
                                <Button className="button button-cancel not-mobile" onClick={()=>cancel()} fullWidth>{t("language_text4")}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                
                            </Grid>
                            <Grid item xs={7}>
                            <Button className="button" onClick={()=>closeDialog()} fullWidth>{t("language_text5")}</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default LanguageComponent;